<template>
  <!-- app nav -->
  <AppNav v-if="appView">최저가 신고 보상제</AppNav>

  <div class="promotion" :style="{ 'padding-top': appPadding }">
    <section class="main_section">
      <img v-img-lazy-loading :data-url="imgUrls.main_img" class="main_img" alt="main_img" />
    </section>
    <section class="desc_section">
      <p class="title">최저가 신고 보상제란?</p>
      <p class="desc">
        구매한 튜터링 수강권이 타사 수강권보다 저렴하지
        <span class="showOnlyMobile"><br /></span>
        않다면 최초 신고자에게는
        <b class="brown">차액의 최대 10배</b>
        를,
        <br />
        최초 신고자와 동일한 조건의 수강권을
        <span class="showOnlyMobile"><br /></span>
        구매한 모든 분께는
        <b class="brown">차액만큼 보상</b>
        해드리고,
        <br />
        튜터링은 업계최저가 기준에 맞추어 가격을 조정합니다.
      </p>
    </section>
    <section class="benefit_section">
      <p class="title">최저가 신고 혜택</p>
      <p class="desc">차액 최대 10배 보상</p>
      <img v-img-lazy-loading :data-url="imgUrls.marketprice_reward" class="img_gift_card" alt="marketprice_reward" />
      <p class="desc desc_small">
        *해당 혜택은 최저가 보상 조건 심사가
        <br />
        통과된 경우에 지급됩니다.
      </p>
      <!-- <img v-img-lazy-loading :data-url="imgUrls.reward_table" class="img_table" alt="reward_table" /> -->
      <div class="benefit_card">
        <div class="benefit_card_label">신청 대상</div>
        <div class="benefit_card_desc">2022년 11월 23일 이후 튜터링 수강권 구매 고객</div>
        <div class="benefit_card_label">신청 대상 수강권</div>
        <div class="benefit_card_desc">365 올패스 스탠다드 (글로벌)</div>
        <div class="benefit_card_label">신청 기간</div>
        <div class="benefit_card_desc">튜터링 수강권 구매 후 1개월 이내</div>
        <div class="benefit_card_label">보상 조건</div>
        <div class="benefit_card_collapse_button" @click="toggleCollapse1">
          최저가 최초 신고자 : 차액 10배 보상
          <img v-if="!isOpenCollapse1" v-img-lazy-loading :data-url="imgUrls.ic_arrow_down" class="icon" />
          <img v-if="isOpenCollapse1" v-img-lazy-loading :data-url="imgUrls.ic_arrow_up" class="icon" />
        </div>
        <div class="benefit_card_collapse_contents" v-if="isOpenCollapse1">
          튜터링 수강권
          <b style="text-decoration: underline">구매일</b>
          에 동일 조건의 타사 수강권 상시 판매가가 더 저렴한 것을 최초로 신고하여 심사가 통과된 경우 차액 10배 보상
          <br />
          (튜터링 수강권 구매시점과 타사 수강권 판매 시점 동일 조건)
        </div>
        <div class="benefit_card_collapse_button" @click="toggleCollapse2">
          최저가 일반 보상 대상자 : 차액 보상
          <img v-if="!isOpenCollapse2" v-img-lazy-loading :data-url="imgUrls.ic_arrow_down" class="icon" />
          <img v-if="isOpenCollapse2" v-img-lazy-loading :data-url="imgUrls.ic_arrow_up" class="icon" />
        </div>
        <div class="benefit_card_collapse_contents" v-if="isOpenCollapse2">
          최초 신고 접수한 고객의 구매일부터 신고 접수일 사이에 동일 수강권을 구매한 모든 고객에 차액 상당 보상
          (신고여부 무관)
          <br />
          <br />
          ex. "[365 올패스] Global 12개월 96회 20분" 최저가 신고 접수
          <br />
          <ul>
            <li class="benefit_card_list_item" style="color: #85808d">최초 신고 고객의 접수일자: 2022년 12월 10일</li>
            <li class="benefit_card_list_item" style="color: #85808d">
              최초 신고 고객의 수강권 구매일: 2022년 11월 30일
            </li>
          </ul>
          → 최저가 일반 보상 대상자 (차액 보상)
          <br />
          2022년 11월 30일~2022년 12월 10일 중 [365 올패스] Global 12개월 96회 20분 수강권을 구매한 모든 고객 (환불 제외)
        </div>
        <div class="benefit_card_label" style="margin-top: 20px">차액 보상 금액</div>
        <div class="benefit_card_desc" style="margin-bottom: 0">
          <ul>
            <li class="benefit_card_list_item">차액 금액은 1만원 단위 올림 적용하여 보상</li>
            <li class="benefit_card_list_item">1만원 미만 차액은 1만원으로 동일 보상</li>
            <li class="benefit_card_list_item" style="color: #85808d">
              차액 = 구매 시점의 튜터링 수강권 상시 판매가 - 동일 시점의 타사 수강권 상시 판매가
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="report_reward_section">
      <p class="title">신고 접수 방법</p>
      <div class="card_container">
        <div class="card">
          <div class="step">STEP. 1</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_01" class="icon" alt="icon" />
          <div class="text">
            최저가 보상 기준
            <br />
            및 참여자 조건 확인
          </div>
        </div>
        <div class="card">
          <div class="step">STEP. 2</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_02" class="icon" alt="icon" />
          <div class="text">
            양식을 작성하여
            <br />
            이메일로 신청 접수
          </div>
        </div>
        <div class="card">
          <div class="step">STEP. 3</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_03" class="icon" alt="icon" />
          <div class="text">
            보상 기준에 따라
            <br />
            심사 진행
          </div>
        </div>
        <div class="card">
          <div class="step">STEP. 4</div>
          <img v-img-lazy-loading :data-url="imgUrls.emoji_04" class="icon" alt="icon" />
          <div class="text">
            심사 결과에 따라
            <br />
            <b>리워드 지급</b>
          </div>
        </div>
      </div>
      <button type="button" class="button_form" @click="openSample">신청 양식 확인하기</button>
    </section>

    <!-- 신청 양식 확인하기 클릭시 나오는 부분 -->
    <div v-if="isSampleClicked" class="common-modal common-modal--dimmed" style="display: block">
      <div class="common-modal__modal modal__certificate" v-click-outside="openSample">
        <div class="common-modal__content">
          <h1 class="title">
            최저가 신고 보상제
            <br />
            신청 양식
          </h1>
          <div class="description">
            아래 양식을 작성하여
            <br />
            contact@tutoring.co.kr로 이메일을 보내주세요.
          </div>
          <div class="card">
            <div class="line">
              <span class="number">1</span>
              <span>튜터링 가입 이메일 계정</span>
            </div>
            <div class="line">
              <span class="number">2</span>
              <span>튜터링에서 구매한 수강권</span>
            </div>
            <div class="line">
              <span class="number">3</span>
              <span>튜터링 수강권 구매 일자</span>
            </div>
            <div class="line">
              <span class="number">4</span>
              <span>타사 수강권 판매 업체</span>
            </div>
            <div class="line">
              <span class="number">5</span>
              <span>타사 수강권명 & 가격</span>
            </div>
            <div class="line">
              <span class="number">6</span>
              <span>타사 수강권 판매 일자</span>
            </div>
            <div class="line">
              <span class="number">7</span>
              <span>
                타사 수강권의 판매 일자를 확인할
                <br />
                <span style="margin-left: 24px">수 있는 캡처화면이나 이미지 등을</span>
                <br />
                <span style="margin-left: 24px">첨부해주세요.</span>
                <div class="small_text" style="margin-top: 4px; margin-left: 24px">
                  ex. 캡처일자가 포함된 이미지, 정가/상시판매가/
                  <br />
                  할인액 확인이 가능한 타사 결제 상세 내역.
                </div>
              </span>
            </div>
          </div>
          <div class="notice">
            <div class="title">유의사항</div>
            <ul style="margin-left: 10px">
              <li class="list__item">
                튜터링 수강권을 구매한 시점의 타사 수강권 상시 판매가를 기준으로 최저가 여부를 심사합니다.
              </li>
              <li class="list__item">
                구매 시점과 판매 시점이 상이한 경우 최저가 보상이 불가능하니 이 점 유의하여 접수해주시기 바랍니다.
              </li>
            </ul>
          </div>
        </div>
        <button type="button" class="modal__certificate__close" @click="openSample">닫기</button>
      </div>
    </div>

    <section class="low_price_report">
      <p class="title">
        최저가
        <br />
        신고 접수 현황
      </p>
      <div class="report__slide__wrapper" v-if="lowReportIng.length === 0 && lowReportDone.length === 0">
        <div class="course__slide">
          <swiper
            :slides-per-view="3"
            :space-between="18"
            :centeredSlides="true"
            :slideToClickedSlide="true"
            :initialSlide="0"
          >
            <swiper-slide class="slide__card none_both">
              <p class="none_title">{{ lowReportBothNone.title }}</p>
              <p class="none_desc">{{ lowReportBothNone.desc }}</p>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div v-if="lowReportIng.length !== 0 || lowReportDone.length !== 0">
        <div class="report__slide__wrapper" :class="{ fix_width: lowReportIng.length < 3 }">
          <div class="course__slide">
            <swiper
              :slides-per-view="3"
              :space-between="18"
              :centeredSlides="true"
              :slideToClickedSlide="true"
              :initialSlide="0"
            >
              <swiper-slide class="slide__card" v-for="ing in lowReportIng" :key="ing">
                <div class="label">심사 중</div>
                <p class="plan_name">{{ ing.title }}</p>
                <p class="purchase_date">{{ ing.date }} 구매 기준</p>
              </swiper-slide>
              <swiper-slide class="slide__card none" v-if="lowReportIng.length === 0">
                <div class="label">심사 중</div>
                <p class="plan_name">{{ lowReportIngNone.title }}</p>
                <p class="purchase_date">{{ lowReportIngNone.desc }}</p>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="report__slide__wrapper" :class="{ fix_width: lowReportDone.length < 3 }">
          <div class="course__slide">
            <swiper
              :slides-per-view="3"
              :space-between="18"
              :centeredSlides="true"
              :slideToClickedSlide="true"
              :initialSlide="0"
            >
              <swiper-slide class="slide__card" v-for="done in lowReportDone" :key="done">
                <div class="label">보상 완료</div>
                <p class="plan_name">{{ done.title }}</p>
                <p class="purchase_date">{{ done.date }} 구매 기준</p>
              </swiper-slide>
              <swiper-slide class="slide__card none" v-if="lowReportDone.length === 0">
                <div class="label">보상 완료</div>
                <p class="plan_name">{{ lowReportDoneNone.title }}</p>
                <p class="purchase_date">{{ lowReportDoneNone.desc }}</p>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="low_price_section">
      <p class="title">
        최저가
        <br />
        신고 보상 조건
      </p>
      <p class="desc">
        영어회화 수업의 퀄리티를 좌우하는
        <br />
        아래 요소를 모두 갖추고 있는
        <br />
        1:1 영어회화 수강권이어야 합니다.
      </p>
      <div class="card">
        <img v-img-lazy-loading :data-url="imgUrls.reward_number01" class="card_number" alt="main_img" />
        <div class="card_title">엄격한 튜터 채용 및 운영 관리</div>
        <div class="card_desc">
          수업에서 가장 중요한 튜터를 채용하고
          <br />
          트레이닝 후 실제 수업 진행 상황 모니터링까지
          <br />
          모두 직접 진행하여야 합니다.
        </div>
        <img v-img-lazy-loading :data-url="imgUrls.reward_img01" class="card_img" alt="main_img" />
      </div>
      <div class="card">
        <img v-img-lazy-loading :data-url="imgUrls.reward_number02" class="card_number" alt="main_img" />
        <div class="card_title">수업하는 튜터의 전문성</div>
        <div class="card_desc">
          관련 학과 졸업증명서 혹은 티칭 자격증 보유하고 있고,
          <br />
          교육 기관에서의 티칭 경력이 있는 튜터가
          <br />
          1:1영어회화 수업을 진행해야 합니다.
        </div>
        <img v-img-lazy-loading :data-url="imgUrls.reward_img02" class="card_img" alt="main_img" />
      </div>
      <div class="card">
        <img v-img-lazy-loading :data-url="imgUrls.reward_number03" class="card_number" alt="main_img" />
        <div class="card_title">100명 이상의 대규모 튜터풀</div>
        <div class="card_desc">
          튜터링의 대규모 튜터풀과 비교 가능한
          <br />
          글로벌 100명 이상, 원어민 50명 이상을 체계적으로
          <br />
          관리하는 경우만 보상 조건에 포함됩니다.
        </div>
        <img
          v-img-lazy-loading
          :data-url="imgUrls.reward_img03"
          class="card_img"
          alt="main_img"
          style="margin-bottom: 13px"
        />
        <div class="card_desc_small">
          *튜터링 튜터 수: 글로벌(영어 사용국) 450명 이상,
          <br />
          원어민(영어 모국어) 90명 이상
        </div>
      </div>
      <div class="card">
        <img v-img-lazy-loading :data-url="imgUrls.reward_number04" class="card_number" alt="main_img" />
        <div class="card_title">
          체계적인 커리큘럼 설계
          <br />
          및 수업 콘텐츠 개발
        </div>
        <div class="card_desc">
          각 레벨별 체계적인 커리큘럼이 있어야 하며,
          <br />
          커리큘럼에 맞는 수업 교재를 직접 연구하고 제작하여
          <br />
          수업에 활용하여야 합니다.
        </div>
        <img v-img-lazy-loading :data-url="imgUrls.reward_img04" class="card_img" alt="main_img" />
      </div>
    </section>
    <!-- 하단 유의 사항  -->
    <section class="notice_section">
      <div class="container">
        <p class="title">이벤트 유의사항</p>
        <ul>
          <li class="list__item">
            최저가 신고는 위 신청 조건 및 양식을 확인하시어 튜터링 이메일로 접수해주시기 바랍니다.
            (contact@tutoring.co.kr)
          </li>
          <li class="list__item">
            튜터링 회원으로 구매한 내역이 확인되지 않거나 신고 양식에 맞지 않는 경우 신고가 취소될 수 있으며, 보상은
            신고자 본인에게만 제공됩니다.
          </li>
          <li class="list__item">보상 혜택으로 지급되는 상품권은 모바일 상품권으로 SMS통해 지급됩니다.</li>
          <li class="list__item">
            <b>수강권별 최초 신고자*</b>
            에 한해 심사 완료 후 차액의 10배 상당 모바일 상품권이 지급되며, 최초 신고 후 보상 완료된 수강권의
            구매일~접수일 사이에 동일한 수강권을 구매한 고객 전원(
            <b>최저가 일반 보상 대상자**</b>
            )에게는 차액 상당의 모바일 상품권이 지급됩니다.
          </li>

          <li class="title_small" style="margin-top: 20px"><b>[수강권별 최초 신고자* 기준]</b></li>
          <li class="list__item">최저가 신고 접수 후 최초로 보상이 완료된 건을 의미합니다.</li>
          <li class="list__item">접수 순번은 보상 신고 접수하시면 이메일로 회신드릴 예정입니다.</li>
          <li class="list__item">
            최초 신고자의 신고 건 심사 후 보상 기준에 충족되지 않은 경우 차순위 접수자의 신고 건에 대한 심사를
            진행합니다.
          </li>
          <li class="list__item">
            보상을 받은 후 수강권을 재구매한 경우, 재구매 일자의 수강권 판매 가격으로 추가 신고 접수가 가능합니다.
          </li>
          <li class="list__item" style="margin-bottom: 20px">
            최초 신고자로 심사 통과된 경우 차액의 10배 보상이 지급되며, 최저가 일반 보상과는 중복 지급되지 않습니다.
          </li>

          <li class="title_small" style="margin-top: 20px"><b>[최저가 일반 보상자** 기준]</b></li>
          <li class="list__item">
            최저가 신고 접수 후 보상이 완료된 수강권을 최초 신고 고객의 수강권 구매 일자와 신고 접수 일자 사이에 동일한
            가격으로 구매한 고객을 의미합니다.
          </li>
          <li class="list__item_none" style="margin-left: 20px">
            - 예시) “[365 올패스] Global 12개월 96회 20분” 최저가 신고 접수
          </li>
          <li class="list__item_none" style="margin-left: 20px">- 최초 신고 고객의 접수일자: 2022년 12월 10일</li>
          <li class="list__item_none" style="margin-left: 20px">- 최초 신고 고객의 수강권 구매일: 2022년 11월 30일</li>
          <li class="list__item_none" style="margin-left: 20px">
            →
            <b>최저가 일반 보상 대상자</b>
            : 2022년 11월 30일~2022년 12월 10일 중 [365 올패스] Global 12개월 96회 20분 수강권을 구매한 모든 고객 (환불
            제외)
          </li>
          <li class="list__item">
            최저가 일반 보상자는 최저가 신고 여부와 상관없이 조건이 충족되는 모든 고객에 차액 상당의 상품권으로 보상이
            지급될 예정입니다.
          </li>
          <li class="list__item" style="margin-bottom: 20px">
            최저가 일반 보상 대상자의 경우 최초 신고자에 대한 심사 완료 후 개별적으로 보상 관련 안내를 드릴 예정입니다.
          </li>

          <li class="list__item">보상 신고 후 심사까지는 영업일 기준 최대 10일이 소요될 수 있습니다.</li>
          <li class="list__item">본 이벤트는 사전 예고없이 변경, 종료될 수 있습니다.</li>
          <li class="list__item">
            관련 문의 사항은 튜터링 앱 내 [고객센터 > 1:1 채팅문의] 혹은 이메일(contact@tutoring.co.kr)로 연락주시기
            바랍니다.
          </li>
        </ul>
        <p class="title">최저가 신고 보상제 상세 기준</p>
        <ul>
          <li class="list__item">서비스 비교 조건 4가지와 수강권 비교 조건이 모두 충족되어야 보상이 가능합니다.</li>
          <li class="title_small" style="margin-top: 20px"><b>[서비스 조건 충족 기준]</b></li>
          <li class="list__item">
            튜터 직접 채용/교육/모니터링: 업체에서 튜터를 직접 채용하고트레이닝 및 모니터링까지 모두 직접 진행하여야
            합니다.
          </li>
          <li class="list__item">
            튜터의 전문성: 채용된 튜터는 모두 관련 학과 졸업증명서 혹은 티칭 자격증 보유하고 있거나 2년 이상의 티칭
            경력이 있어야 합니다.
          </li>
          <li class="list__item">
            대규모 튜터풀: 글로벌(영어 사용국) 튜터가 100명 이상, 원어민(영어 모국어)튜터 50명 이상을 체계적으로
            관리하여야 합니다.
          </li>
          <li class="list__item">
            레벨별 커리큘럼 및 수업자료: 각 레벨별 체계적인 커리큘럼이 있어야 하며, 커리큘럼에 따라 교재/자료를 활용하여
            수업이 진행되어야 합니다.
          </li>
          <li class="list__item">
            타사 수강권 판매처의 서비스가 4가지 기준에 모두 부합하여야 하며, 실제 조건이 충족되지만 공식 홈페이지나
            소개자료, 고객 문의 등에서 확인이 불가한 경우에는 보상이 불가합니다.
          </li>

          <li class="title_small" style="margin-top: 20px"><b>[수강권 비교 기준]</b></li>
          <li class="list__item">
            최저가 비교 수강권의 가격은 별도 프로모션이 적용되지 않은 상시 판매가를 기준으로 합니다.
          </li>
          <li class="list__item">
            판매처별 수강권 판매 옵션이 상이한 경우 총 판매가 기준 분당 가격 동일 조건 판매시로 금액을 환산하여 가격을
            비교합니다. (수강권 분당단가 = (총 수업 횟수*수업당 분수)/판매가)
          </li>
          <li class="list__item">
            튜터링의 경우 모든 시간대 실시간 수업이 가능하기 때문에 특정 시간대나 요일 조건부 할인이 적용되지 않은 상시
            이용 조건의 수강권과 최우선으로 비교하며, 만약 신고 보상 조건에는 부합되지만 상시 수업 가능한 수강권이 없는
            경우, 새벽 시간대 제외 (KST 0시 ~ 6시) 모든 시간대 수업의 가격이 동일한 정규 수강권으로 비교하여야 합니다.
            동일 기간, 총 시간(분수)의 자유 수강권과 정규 수강권이 모두 존재하는 경우 자유 수강권을 기준으로 비교합니다.
          </li>
          <li class="list__item" style="margin-left: 20px">
            ex. 09~16시 중 시간 지정 수강권 (신고 불가) / 06~24시 중 시간 지정 수강권 (신고 가능) / 06~24시 중 자유
            수강권 (신고 가능)
          </li>
          <li class="list__item">
            수강권 외 별도 교재나 상품 등이 결합된 상품은 신고 대상에서 제외됩니다. (ex. 전자 기기결합 수강권 등)
          </li>
          <li class="list__item">
            튜터링 수강권과 가격 비교가 어려운 경우(ex. 튜터별 수강료가 상이한 경우)는 신고 대상에서 제외됩니다.
          </li>
          <li class="list__item">
            튜터링 수강권 중 365올패스 정기권, 365 올패스 프리미엄(네이티브), 작심패키지는 신고 대상에서 제외됩니다.
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import vClickOutside from 'click-outside-vue3';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { AxiosResponse } from 'axios';
import { storage, native } from '@/tools';
import inappTools from '@/common/inappTools';
import api from '@/common/api';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';

import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';

import 'swiper/swiper-bundle.css';

export default {
  name: 'Summer2022',
  components: {
    AppNav,
    Swiper,
    SwiperSlide,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        main_img: '//res.tutoring.co.kr/res/images/img/marketprice/main_img03.png',
        reward_number01: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number01.png',
        reward_number02: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number02.png',
        reward_number03: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number03.png',
        reward_number04: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number04.png',
        reward_img01: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img01.png',
        reward_img02: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img02.png',
        reward_img03: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img03.png',
        reward_img04: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img04.png',
        emoji_01: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_01.png',
        emoji_02: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_02.png',
        emoji_03: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_03.png',
        emoji_04: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_04.png',
        marketprice_reward: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_reward.png',
        reward_table: '//res.tutoring.co.kr/res/images/img/marketprice/reward_table.png',
        ic_arrow_down: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_16px.png',
        ic_arrow_up: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_up_16px.png',
      },
      bottomCtaUp: false,
      isMobileBottomSheetOpen: false,
      isSampleClicked: false,
      isOpenCollapse1: false,
      isOpenCollapse2: false,
      lowReportIng: '',
      lowReportIngNone: '',
      lowReportDone: '',
      lowReportDoneNone: '',
      lowReportBothNone: '',
    };
  },
  created() {
    this.getLoadStrings();
  },
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },
  methods: {
    purchasePlan() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        this.trackingUserAction(`readytogo_footer`);
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all/pass`; // 수강신청 메뉴
        window.location.href = url;
      }
    },
    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },
    openSample(): void {
      this.isSampleClicked = !this.isSampleClicked;
    },
    toggleCollapse1(): void {
      this.isOpenCollapse1 = !this.isOpenCollapse1;
    },
    toggleCollapse2(): void {
      this.isOpenCollapse2 = !this.isOpenCollapse2;
    },
    async getLoadStrings(): Promise<any> {
      this.loadStrings = await api.get('/mvc/load_strings?lang=kr').then((response: AxiosResponse) => response);
      this.lowReportIng = JSON.parse(
        this.loadStrings.LOW_REPORT_ING ? this.loadStrings.LOW_REPORT_ING : JSON.stringify([]),
      );
      this.lowReportIngNone = JSON.parse(this.loadStrings.LOW_REPORT_ING_NONE);
      this.lowReportDone = JSON.parse(
        this.loadStrings.LOW_REPORT_DONE ? this.loadStrings.LOW_REPORT_DONE : JSON.stringify([]),
      );
      this.lowReportDoneNone = JSON.parse(this.loadStrings.LOW_REPORT_DONE_NONE);
      this.lowReportBothNone = JSON.parse(this.loadStrings.LOW_REPORT_BOTH_NONE);
    },
  },

  computed: {
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped src="../../assets/newPromotions.scss"></style>
<style lang="scss" scoped>
.showOnlyMobile {
  display: none;
}
.showOnlyWeb {
  display: inline;
}
@media (max-width: 991px) {
  .showOnlyMobile {
    display: inline;
  }
  .showOnlyWeb {
    display: none;
  }
}
.main_section {
  background: #efe7dc;
  text-align: center;
  img.main_img {
    display: block;
    margin: 0 auto;
    width: 360px;
    height: 520px;
  }
}
.desc_section {
  background: #f4f1ef;
  padding: 40px 0;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  }
  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    .brown {
      color: #c58129;
    }
  }
}
.low_price_report {
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 60px 30px 70px 30px;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 30px;
  }
  .report__slide__wrapper {
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 12px;
    &.fix_width {
      width: 380px;
    }
    & .course__slide {
      width: 960px;
      min-width: 960px;
      & .slide__card {
        background-color: #fefefe;
        max-width: 266px;
        padding: 16px 20px;
        border-radius: 20px;
        overflow: hidden;
        text-align: left;
        border: solid 1px #fefefe;
        & .label {
          display: inline-block;
          padding: 4px 8px;
          font-weight: 700;
          font-size: 12px;
          color: #85808d;
          background: #e8e6ed;
          border-radius: 4px;
          margin-bottom: 12px;
        }
        & .plan_name {
          font-weight: 700;
          font-size: 14px;
          color: #000000;
          margin-bottom: 6px;
        }
        & .purchase_date {
          font-weight: 400;
          font-size: 12px;
          color: #666666;
        }
      }
      & .slide__card.none {
        background-color: #f1f0f5;
        .label {
          display: inline-block;
          padding: 4px 8px;
          font-weight: 700;
          font-size: 12px;
          color: #f8f8fa;
          background: #d6d3dd;
          border-radius: 4px;
          margin-bottom: 12px;
        }
        .plan_name {
          color: #afabb7;
        }
        .purchase_date {
          color: #afabb7;
        }
      }
      & .slide__card.none_both {
        background-color: #ffffff;
        max-width: 266px;
        padding: 33px 20px;
        border-radius: 20px;
        overflow: hidden;
        text-align: left;
        border: solid 1px #ffffff;
        text-align: center;
        color: #85808d;
        .none_title {
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 6px;
        }
        .none_desc {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    & .course__slide.orange {
      & .slide__card {
        border: solid 1px #f99909;
        & .label {
          color: #f99909;
          background: rgba(255, 168, 38, 0.2);
        }
      }
    }
  }
}
.low_price_section {
  padding: 80px 0 35px 0;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 10px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-bottom: 40px;
  }
  .card {
    margin-bottom: 40px;
    .card_number {
      width: 30px;
      height: 30px;
      margin-bottom: 16px;
    }
    .card_title {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #b8894d;
      margin-bottom: 10px;
    }
    .card_desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      margin-bottom: 24px;
    }
    .card_desc_small {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      margin-bottom: 40px;
    }
    .card_img {
      width: 272px;
      height: 190px;
    }
  }
}
.report_reward_section {
  padding: 80px 0;
  background: #f2eee8;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 40px;
  }
  .button_form {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #666666;
  }
  .card_container {
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 25px;
    text-align: center;
    width: 70%;
    max-width: 600px;
    margin: 0 auto 30px auto;
    @media (max-width: 991px) {
      padding: 40px 25px 10px 25px;
    }
    .card {
      width: 25%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        width: 50%;
        margin-bottom: 30px;
      }
      .step {
        padding: 3px 10px;
        width: 60px;
        background: #5e5a66;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      .icon {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #333;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }
}
.benefit_section {
  padding: 80px 0 40px 0;
  background: #ffffff;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 6px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
  .desc_small {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 40px;
  }
  img.img_gift_card {
    width: 209px;
    margin: 30px auto 14px auto;
  }
  img.img_table {
    width: 312px;
    margin: 0 auto;
  }
  .benefit_card {
    background: #f3f3f3;
    border-radius: 20px;
    max-width: 300px;
    margin: 0 auto;
    padding: 40px 24px;
    text-align: left;
    ul {
      margin-left: 7px;
    }
    &_label {
      font-weight: 700;
      font-size: 16px;
      color: #3f3d44;
      margin-bottom: 4px;
    }
    &_desc {
      font-weight: 400;
      font-size: 14px;
      color: #3f3d44;
      margin-bottom: 20px;
      line-height: 20px;
    }
    &_collapse_button {
      padding: 15px 14px;
      font-weight: 400;
      font-size: 14px;
      color: #3f3d44;
      background: #ffffff;
      cursor: pointer;
      margin-bottom: 6px;
      .icon {
        width: 16px;
        height: 16px;
        float: right;
        vertical-align: text-top;
      }
    }
    &_collapse_contents {
      padding: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #85808d;
      background: #ffffff;
      border-top: 1px solid #e8e6ed;
      margin-bottom: 6px;
      margin-top: -6px;
      line-height: 20px;
    }
    &_list_item {
      position: relative;
      font-weight: 400;
      font-size: 14px;
      color: #3f3d44;
      text-indent: 0;
      &:before {
        content: '\B7';
        position: absolute;
        top: 0;
        left: -7px;
      }
    }
  }
}
.notice_section {
  background: #eeeeee;
  padding: 60px 24px 40px 24px;
  color: #666666;
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 10px;
  }
  .title_small {
    font-size: 12px;
    line-height: 20px;
    color: #666666;
  }
  ul {
    margin-bottom: 40px;
  }
  .list__item {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    text-indent: 0;
    &:before {
      content: '\B7';
      position: absolute;
      top: 0;
      left: -7px;
    }
  }
  .list__item_none {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    text-indent: 0;
  }
}
.modal__certificate {
  width: 320px !important;
  height: auto;
  padding: 40px 0 0 0;
  .common-modal__content {
    overflow-y: auto;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 12px;
  }
  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #666666;
    margin-bottom: 15px;
  }
  .card {
    background: #f8f8fa;
    border-radius: 10px;
    padding: 20px 25px;
    text-align: left;
    margin: 0 30px 25px 30px;
    .line {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      &:last-child {
        margin-bottom: 0;
      }
      .number {
        background: #000000;
        border-radius: 11px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
        padding: 1.4px 6px 0.5px 5px;
        margin-right: 8px;
      }
      .small_text {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;

        color: #666666;
      }
    }
  }
  .notice {
    padding: 24px;
    text-align: left;
    background: #f1f0f5;
    border-radius: 0 0 10px 10px;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #3f3d44;
      margin-bottom: 10px;
      text-align: left;
    }
    .list__item {
      position: relative;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3f3d44;
      text-indent: 0;
      margin-top: 0;
      &:before {
        content: '\B7';
        position: absolute;
        top: 0;
        left: -7px;
      }
    }
  }
}
</style>
