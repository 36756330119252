import { render } from "./ReportRewardSystem.vue?vue&type=template&id=08f87295&scoped=true"
import script from "./ReportRewardSystem.vue?vue&type=script&lang=ts"
export * from "./ReportRewardSystem.vue?vue&type=script&lang=ts"

import "../../assets/newPromotions.scss?vue&type=style&index=0&id=08f87295&lang=scss&scoped=true"
import "./ReportRewardSystem.vue?vue&type=style&index=1&id=08f87295&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-08f87295"
/* hot reload */
if (module.hot) {
  script.__hmrId = "08f87295"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('08f87295', script)) {
    api.reload('08f87295', script)
  }
  
  module.hot.accept("./ReportRewardSystem.vue?vue&type=template&id=08f87295&scoped=true", () => {
    api.rerender('08f87295', render)
  })

}

script.__file = "src/page/promotion/ReportRewardSystem.vue"

export default script