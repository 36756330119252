
import vClickOutside from 'click-outside-vue3';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { AxiosResponse } from 'axios';
import { storage, native } from '@/tools';
import inappTools from '@/common/inappTools';
import api from '@/common/api';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';

import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';

import 'swiper/swiper-bundle.css';

export default {
  name: 'Summer2022',
  components: {
    AppNav,
    Swiper,
    SwiperSlide,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        main_img: '//res.tutoring.co.kr/res/images/img/marketprice/main_img03.png',
        reward_number01: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number01.png',
        reward_number02: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number02.png',
        reward_number03: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number03.png',
        reward_number04: '//res.tutoring.co.kr/res/images/img/marketprice/reward_number04.png',
        reward_img01: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img01.png',
        reward_img02: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img02.png',
        reward_img03: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img03.png',
        reward_img04: '//res.tutoring.co.kr/res/images/img/marketprice/reward_img04.png',
        emoji_01: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_01.png',
        emoji_02: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_02.png',
        emoji_03: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_03.png',
        emoji_04: '//res.tutoring.co.kr/res/images/img/marketprice/emoji_04.png',
        marketprice_reward: '//res.tutoring.co.kr/res/images/img/marketprice/marketprice_reward.png',
        reward_table: '//res.tutoring.co.kr/res/images/img/marketprice/reward_table.png',
        ic_arrow_down: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_16px.png',
        ic_arrow_up: '//res.tutoring.co.kr/res/images/img/marketprice/ic_dropdown_up_16px.png',
      },
      bottomCtaUp: false,
      isMobileBottomSheetOpen: false,
      isSampleClicked: false,
      isOpenCollapse1: false,
      isOpenCollapse2: false,
      lowReportIng: '',
      lowReportIngNone: '',
      lowReportDone: '',
      lowReportDoneNone: '',
      lowReportBothNone: '',
    };
  },
  created() {
    this.getLoadStrings();
  },
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },
  methods: {
    purchasePlan() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        this.trackingUserAction(`readytogo_footer`);
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all/pass`; // 수강신청 메뉴
        window.location.href = url;
      }
    },
    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },
    openSample(): void {
      this.isSampleClicked = !this.isSampleClicked;
    },
    toggleCollapse1(): void {
      this.isOpenCollapse1 = !this.isOpenCollapse1;
    },
    toggleCollapse2(): void {
      this.isOpenCollapse2 = !this.isOpenCollapse2;
    },
    async getLoadStrings(): Promise<any> {
      this.loadStrings = await api.get('/mvc/load_strings?lang=kr').then((response: AxiosResponse) => response);
      this.lowReportIng = JSON.parse(
        this.loadStrings.LOW_REPORT_ING ? this.loadStrings.LOW_REPORT_ING : JSON.stringify([]),
      );
      this.lowReportIngNone = JSON.parse(this.loadStrings.LOW_REPORT_ING_NONE);
      this.lowReportDone = JSON.parse(
        this.loadStrings.LOW_REPORT_DONE ? this.loadStrings.LOW_REPORT_DONE : JSON.stringify([]),
      );
      this.lowReportDoneNone = JSON.parse(this.loadStrings.LOW_REPORT_DONE_NONE);
      this.lowReportBothNone = JSON.parse(this.loadStrings.LOW_REPORT_BOTH_NONE);
    },
  },

  computed: {
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },
  watch: {},
};
